import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: () => {
      return "/home/main";
    },
    component: () => import("../views/HomeView.vue"),

    children: [
      {
        //首页
        path: "/home/main",
        name: "main",
        component: () => import("../components/main/main.vue"),

        meta: {
          a: localStorage.getItem("TOKEN_KEY") ? true : false,
        },
      },
      {
        path: '/cppm',
        name: 'cppm',
        component: () => import("../components/cppm/cppm.vue")
      },
      {
        path: '/scmp',
        name: 'scmp',
        component: () => import("../components/scmp/scmp.vue")
      },
      {
        path: '/pmp',
        name: 'pmp',
        component: () => import("../components/pmp/pmp.vue")
      },
      {
        path: '/cpsm',
        name: 'cpsm',
        component: () => import("../components/cpsm/cpsm.vue")
      },
      {
        path: '/scm',
        name: 'scm',
        component: () => import("../components/scm/scm.vue")
      },
      {
        path: '/certificate',
        name: 'certificate',
        component: () => import("../components/certificate/certificate.vue")
      },
      {
        path: '/team',
        name: 'team',
        component: () => import("../components/team/team.vue")
      },
      {
        path: '/about',
        name: 'about',
        component: () => import("../components/about/about.vue")
      },
      {
        path: '/contactus',
        name: 'contactus',
        component: () => import("../components/contact/contactus.vue")
      },
      {
        path: '/introduce',
        name: 'introduce',
        component: () => import("../components/train/introduce.vue")
      },
      {
        path: '/flow',
        name: 'flow',
        component: () => import("../components/train/flow.vue")
      },
      {
        path: '/information',
        name: 'information',
        component: () => import("../components/train/information.vue")
      },
    ],
    meta: {
      linkActiveClass: "",
    },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
})

export default router
